import Typography from "typography"
import irving from "typography-theme-irving"
import sutro from "typography-theme-sutro"
import alton from "typography-theme-alton"


/*
Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
*/

irving.headerFontFamily = [
  "Space Mono",
  "monospace"
];


irving.overrideThemeStyles = ({ rhythm }, options) => ({
  'body': {
    fontSize: '0.7em',
    lineHeight: 1.7
  },
  'a:hover': {
    color: '#FA8072',
  }
})

irving.bodyFontFamily = [
  "Space Mono",
  "monospace"
]

alton.overrideThemeStyles = ({ rhythm }, options) => ({
  'body': {
    fontSize: '0.9em',
  },
  'a': {
    color: '#333',
  },
  'a:hover': {
    color: '#FA8072',
  }
})


alton.headerFontFamily = [
  "Space Mono",
  "monospace"
];

alton.bodyFontFamily = [
  "Space Mono",
  "monospace"
]


const typography = new Typography(irving)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}


export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
