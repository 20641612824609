import React from "react"
import { Link } from "gatsby"

require(`./header.css`)

const Header = ({ siteTitle, menuLinks }) => (
  <nav style={{ padding: 0, margin: 0 }} >
    <div className='menu'>
      <Link to='/' activeClassName='current'>Home</Link>•<Link to='/about' activeClassName='current'>About</Link>•<Link to='/projects' activeClassName='current'>Projects</Link>•<Link to='/publications' activeClassName='current'>Publications & Talks</Link>
    </div>
  </nav>
)

export default Header
